import { makeStyles } from "@mui/styles";
import * as React from "react";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  styled,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import axios from "axios";
import Draggable from "react-draggable";
import urlBackend from "../config.json";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#0F2A47",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const useStyles = makeStyles((theme) => ({
  couponRuleDiaglogContent: {
    width: "70vw",
    height: "35vw",
    border: "#0E86D4 solid 4px",
  },
  couponRuleDiaglogAction: {
    padding: "0rem 1.5rem 1rem !important",
  },
  bedingungsButton: {
    textTransform: "none !important",
  },
  neueRegelnButtonGruppe: {
    float: "right",
  },
  neueRegelnButton: {
    textTransform: "none !important",
    fontWeight: "bold",
  },
  deleteButton: {
    textTransform: "none !important",
    fontWeight: "bold",
    float: "right",
    margin: "10px !important",
  },
  bedingungsInhalt: {
    margin: "0.5vw",
    width: "65vw",
    height: "3vw",
    float: "right",
    border: "#0E86D4 solid 1px",
    borderRadius: "5px 20px 5px",
  },
}));
const GutscheinDialog = (props) => {
  const {
    open,
    handleClose,
    setOpenSuccessSendCoupon,
    setOpenErrorSendCoupon,
    vendorToken,
    selectedIDs,
    newID,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [gegenwertType, setGegenwertType] = useState("");
  const [gegenwertValue, setGegenwertValue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const classes = useStyles();
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getRandomNumber(amount) {
    let digits = "";
    for (let i = 0; i < amount; ++i) digits += Math.floor(Math.random() * 10);

    return digits;
  }

  const handleSendCoupon = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("vendorToken", vendorToken);
    bodyFormData.append("coupon-id", getRandomNumber(10));
    bodyFormData.append("rabattPercent", gegenwertValue);
    bodyFormData.append("warengruppe", gegenwertType);
    bodyFormData.append("gueltigkeitsdauer", startDate + " - " + endDate);

    bodyFormData.append("connection-id", newID);

    bodyFormData.append("einloesebedingung", "einloesebedingung");

    axios({
      method: "post",
      url: urlBackend.BACKEND_SERVER + "/vendor/sendCoupon",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setOpenSuccessSendCoupon(true);
        setOpenErrorSendCoupon(false);
        console.log(response);
      })
      .catch((error) => {
        setOpenErrorSendCoupon(true);
      });
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "bold", fontSize: "180%" }}
          id="draggable-dialog-title"
        >
          Coupon erstellen
        </DialogTitle>
        <DialogContent>
          <Box className={classes.couponRuleDiaglogContent}>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: "120%" }}
                >
                  Gegenwert
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <FormControl
                    sx={{ minWidth: 200, marginLeft: "20px" }}
                    size="small"
                  >
                    {" "}
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Rabatt (in Prozent)"
                      value={gegenwertType}
                      onChange={(event) => setGegenwertType(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Tiernahrung"}>Tiernahrung</MenuItem>
                      <MenuItem value={"Textilien"}>Textilien</MenuItem>
                      <MenuItem value={"Elektronik"}>Elektronik</MenuItem>
                    </TextField>
                  </FormControl>
                  <FormControl
                    sx={{ minWidth: 120, marginLeft: "20px" }}
                    size="small"
                  >
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                      value={gegenwertValue}
                      onChange={(event) =>
                        setGegenwertValue(event.target.value)
                      }
                    />
                  </FormControl>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChangeAccordion("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: "120%" }}
                >
                  Gültigkeit
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Gültig von"
                      inputFormat="MM.dd.yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />

                    <DesktopDatePicker
                      label="Gültig bis"
                      inputFormat="MM.dd.yyyy"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>

        <DialogActions className={classes.couponRuleDiaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClose}>
            Abbrechen
          </ColorButtonCancel>
          <ColorButtonConfirm onClick={handleSendCoupon}>
            Ausstellen
          </ColorButtonConfirm>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GutscheinDialog;
