import * as React from "react";
import { Button, Stack } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
const BackToStart = (props) => {
  return (
    <Stack spacing={1} alignItems="center">
      {/* Haben Sie den Request abgelehnt? */}

      <Stack
        direction="row"
        spacing={1}
        style={
          {
            /* marginTop: "1rem", marginBottom: "1rem" */
          }
        }
      >
        <Button
          variant="contained"
          color="error"
          endIcon={<RestartAltIcon />}
          href="/"
        >
          Neu starten
        </Button>
      </Stack>
    </Stack>
  );
};
export default BackToStart;
