import { Grid } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const EECCLogo = require("../assets/eecc.png");

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    color: "#0F2A47",
    borderTop: ".1vh solid white",
    bottom: 0,
    marginBottom: 0,

    backgroundColor: "#0F2A47 !important",
    /* fontSize: "2vh", */
    width: "100%",
    height: "2.5vh",
    fontFamily: "Montserrat-Light",
  },

  appName: {
    paddingTop: "0.3vh",
    float: "left",
  },
  logoGridItem: {
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  copyrightGridItem: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      fontSize: "60%",
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        alignContent="center"
        direction="row"
        wrap="wrap-reverse"
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className={classes.copyrightGridItem}
        >
          <div>
            {/*  <img
              src={fxFooterLogoMS}
              alt="LogoFx"
              className={classes.footerIconMS}
            /> */}
          </div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className={classes.logoGridItem}
        >
          {/* <img src={BMWK} alt="Logo" className={classes.footerIcon} /> */}
        </Grid>
      </Grid>
    </footer>
  );
};
export default Footer;
