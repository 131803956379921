import { makeStyles } from "@mui/styles";
import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled, Paper, Button, Box } from "@mui/material";

import Draggable from "react-draggable";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles((theme) => ({
  content: {
    width: "70vw",
    height: "fit-content",
    // border: "#0E86D4 solid 4px",
  },
  diaglogAction: {
    padding: "0rem 1.5rem 1rem !important",
  },
  bedingungsButton: {
    textTransform: "none !important",
  },
  neueRegelnButtonGruppe: {
    float: "right",
  },
  neueRegelnButton: {
    textTransform: "none !important",
    fontWeight: "bold",
  },
  deleteButton: {
    textTransform: "none !important",
    fontWeight: "bold",
    float: "right",
    margin: "10px !important",
  },
  bedingungsInhalt: {
    margin: "0.5vw",
    width: "65vw",
    height: "3vw",
    float: "right",
    border: "#0E86D4 solid 1px",
    borderRadius: "5px 20px 5px",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const ConfirmDelete = (props) => {
  const { open, handleClose, handleDelete, selected } = props;
  const classes = useStyles();
  const handleDeleteAll = () => {
    for (let i = 0; i < selected.length; i++) {
      let response = handleDelete(selected[i]);
      console.log(response);
      if (response === null || response === undefined) {
        sleep(5000);
      }
    }
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "bold", fontSize: "180%" }}
          id="draggable-dialog-title"
        >
          Bestätigung
        </DialogTitle>
        <DialogContent>
          <Box className={classes.content}>
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "2vh" }}
            >
              Wollen Sie wirklich die ausgewählten Verbindungen löschen?
            </Paper>
            {selected.map((e) => {
              return <Box>{e}</Box>;
            })}
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClose}>
            Abbrechen
          </ColorButtonCancel>
          <ColorButtonConfirm onClick={handleDeleteAll}>
            Löschen
          </ColorButtonConfirm>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDelete;
