export function filterByPostCode(allconnections, minPostCode, maxPostCode) {
  let result = allconnections;
  if (minPostCode === null && maxPostCode === null) {
    return result;
  }
  if (minPostCode === null && maxPostCode !== null) {
    result = [];
    allconnections.map((e) => {
      if (Number(e.addressZipCode) <= Number(maxPostCode)) {
        result.push(e);
      }
    });
    return result;
  }
  if (minPostCode !== null && maxPostCode === null) {
    result = [];
    allconnections.map((e) => {
      if (Number(e.addressZipCode) >= Number(minPostCode)) {
        result.push(e);
      }
    });
    return result;
  }
  if (minPostCode !== null && maxPostCode !== null) {
    result = [];
    allconnections.forEach((e) => {
      if (
        Number(e.addressZipCode) >= Number(minPostCode) &&
        Number(e.addressZipCode) <= Number(maxPostCode)
      ) {
        result.push(e);
      }
    });
    return result;
  }
}
export function checkIfValidPostCodeRules(regelnList, regelnZusammenhang) {
  // { objekt: "", vergleich: "", werte: "", von:"",bis:"" }
  let error = "";

  if (regelnZusammenhang === "und") {
    /** mehr als 1  "gleich" */
    let equalRules = 0;
    let pairIntervall = 0;
    regelnList.forEach((element) => {
      if (element.vergleich === "==") {
        element += 1;
      }
      if (element.vergleich === "in") {
        pairIntervall += 1;
        console.log(Number(element.von));
        console.log(Number(element.bis));

        if (Number(element.von) > Number(element.bis)) {
          error = "der Intervall wurde falsch definiert !";
        }
        if (
          (element.von === "" && element.bis !== "") ||
          (element.von !== "" && element.bis === "") ||
          (element.von === "" && element.bis === "")
        ) {
          error = "der Intervall wurde nicht ausführlich definiert !";
        }
      }
    });
    if (equalRules > 1) {
      error = "die Postleitzahl darf nicht gleichzeitig 2 Werte gleichen !";
    }
    /** mehr als 1 Paar "größer/kleiner" */
    if (pairIntervall > 1) {
      error = "die Postleitzahl darf nicht gleichzeitig in 2 Intervall sein !";
    }
    /** Paar "größer/kleiner" logisch falsch, zB:  a<2 und a > 2 */
  } /**(regelnZusammenhang === "oder") */ else {
    let minIntervall;
    /** Paar "größer/kleiner" überlappen , zB:  a<2 oder a < 3 */

    /** Paar "größer/kleiner" mit gleich überlappen , zB:  a<2 oder a = 1 */
  }
  return error;
  //TODO
}
