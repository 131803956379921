import * as React from "react";
import { Button, Stack } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const UseCoupon = (props) => {
  const { onClickUseCoupon } = props;
  return (
    <Stack spacing={1} alignItems="center">
      Sie können jetzt den Coupon hier einlösen :
      <Stack
        direction="row"
        spacing={1}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <Button
          variant="contained"
          color="success"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          onClick={onClickUseCoupon}
        >
          Coupon einlösen
        </Button>
      </Stack>
    </Stack>
  );
};
export default UseCoupon;
