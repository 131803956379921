import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import App_Customer from "./App_Customer";
import App_Vendor from "./App_Vendor";
import App_BDR from "./App_BDR";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/Vendor" element={<App_Vendor />}></Route>
        <Route path="/customer" element={<App_Customer />}></Route>
        <Route path="/bdr" element={<App_BDR />}></Route>
        <Route path="/" element={<App_Customer />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
