import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

export const theme = createTheme({
  breakpoints: {
    values: {
      desktop2560: 2560,
      desktop1980: 1980,
      desktop1920: 1920,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          boxShadow: "none",
        },
      },
    },
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    primary: {
      main: "#0E86D4", // blue Grotto
      // main: "#ffff",
    },
    secondary: {
      main: green[500],
    },
    text: {
      title: "#ffff",
      option1: "#0E86D4 !important",
      option2: "#003060 !important",
    },
    borderColor: "#003060", //navi blue
    background: {
      default: "#92A8D1 !important",
      option1: "#68BBE3 !important",
      option2: "#40E0D0 !important",
      titleBox: "#0E86D4", // blue Grotto
      inputBox: "#ffff ", // white
      footer: "#0E86D4", // blue Grotto
    },
  },
});
