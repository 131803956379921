import * as React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const useStyles = makeStyles((theme) => ({
  Receipt: { overflowY: "scroll", height: "100%" },
}));

const Receipt = (props) => {
  const classes = useStyles();
  const { rabatt, rows } = props;
  const invoiceSubtotal = subtotal(rows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceDiscount =
    ((invoiceSubtotal + TAX_RATE * invoiceSubtotal) * rabatt) / 100;
  const invoiceTotal = invoiceSubtotal + invoiceTaxes - invoiceDiscount;
  return (
    <TableContainer component={Paper} className={classes.Receipt}>
      <Table sx={{ minWidth: 200 }} aria-label="spanning table" className={classes.Receipt}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Artikels
            </TableCell>
            <TableCell align="right">Preis</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Desc</TableCell>
            <TableCell align="right">Menge</TableCell>
            <TableCell align="right">Einzel</TableCell>
            <TableCell align="right">Gesamt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.desc}>
              <TableCell>{row.desc}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{row.unit + "€"} </TableCell>
              <TableCell align="right">{ccyFormat(row.price) + "€"}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Netto-Betrag</TableCell>
            <TableCell align="right">
              {ccyFormat(invoiceSubtotal) + "€"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>+ MwSt.</TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
              0
            )} %`}</TableCell>
            <TableCell align="right">
              {ccyFormat(invoiceTaxes) + "€"}
            </TableCell>
          </TableRow>
          <TableRow style={rabatt === 0 ? { display: "none" } : {}}>
            <TableCell style={{ color: "red" }}>Rabatt Coupon</TableCell>
            <TableCell
              style={{ color: "red" }}
              align="right"
            >{`- ${rabatt} %`}</TableCell>
            <TableCell style={{ color: "red" }} align="right">
              {"- " + ccyFormat(invoiceDiscount) + "€"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><strong>Gesamtpreis</strong></TableCell>
            <TableCell colSpan={4} align="right">
              <strong>{ccyFormat(invoiceTotal) + "€"}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default Receipt;
