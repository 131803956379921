import {
  AppBar,
  Grid,
  styled,
  Paper,
  Box,
  TextField,
  Toolbar,
  Button,
  Tooltip,
  Avatar,
  MenuItem,
  Typography,
  Menu,
  Container,
  IconButton,
} from "@mui/material";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GS1Logo = require("../assets/logoGS1.png");
const IDUnionLogo = require("../assets/IDunion_squard.png");
const IDIdealLogo = require("../assets/ID-Ideal.png");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,

  textAlign: "center",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#0F2A47 !important",
    textAlign: "center",
    position: "absolut",
    height: "60px",
    padding: "2px 1vw",
  },
  paper: {
    height: "13vh",
  },
  titleBox: {
    color: theme.palette.text.title,
    textAlign: "center",
    fontSize: "200%",
    fontWeight: "bold",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const pages = [""];
  const settings = ["Kundensicht"];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  let navigate = useNavigate();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSettingUserMenu = (e) => {
    if (e.target.textContent === "Kundensicht") navigate("/customer");
    //if (e.target.textContent === "BDR") navigate("/bdr");
    setAnchorElUser(null);
  };
  return (
    <AppBar position="sticky" className={classes.appbar}>
      <Toolbar disableGutters>
        <BookOnlineIcon
          sx={{
            marginBottom: "4px",
            display: { xs: "none", md: "flex" },
            mr: 1,
          }}
          color="primary"
        />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            marginBottom: "2px",
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "#ffff",
            textDecoration: "none",
          }}
        >
          CouponingDemo
        </Typography>

        <Typography
          sx={{
            flexGrow: 1,
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            color: "#ffff",
            textDecoration: "none",
            textAlign: "right",
            paddingRight: "10px",
            fontWeight: "900",
            fontSize: "x-large",
          }}
        >
          Händlersicht
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <StorefrontTwoToneIcon color="info" fontSize="large" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={(e) => handleSettingUserMenu(e)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
