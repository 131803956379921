import { makeStyles } from "@mui/styles";
import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  styled,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Button,
  Table,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import Draggable from "react-draggable";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles((theme) => ({
  couponRuleDiaglogContent: {
    width: "70vw",
    height: "35vw",
    // border: "#0E86D4 solid 4px",
  },
  couponRuleDiaglogAction: {
    padding: "0rem 1.5rem 1rem !important",
  },
  bedingungsButton: {
    textTransform: "none !important",
  },
  neueRegelnButtonGruppe: {
    float: "right",
  },
  neueRegelnButton: {
    textTransform: "none !important",
    fontWeight: "bold",
  },
  deleteButton: {
    textTransform: "none !important",
    fontWeight: "bold",
    float: "right",
    margin: "10px !important",
  },
  bedingungsInhalt: {
    margin: "0.5vw",
    width: "65vw",
    height: "3vw",
    float: "right",
    border: "#0E86D4 solid 1px",
    borderRadius: "5px 20px 5px",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

var rows = [
  {
    id: "1",
    datum: "Jul 17, 2021",
    produktID: "17314159-9",
    coupon: "57",
    menge: "€120.13",
    IBAN: "AD0684485555103387477352",
    abgeschlossen: "Ja",
  },
  {
    id: "2",
    datum: "Jan 28, 2022",
    produktID: "35292666-3",
    coupon: "92",
    menge: "€366.39",
    IBAN: "PL58066257422546453984939210",
    abgeschlossen: "Ja",
  },
  {
    id: "3",
    datum: "Dec 18, 2022",
    produktID: "27725383-6",
    coupon: "87",
    menge: "€111.38",
    IBAN: "IL492645738049653154708",
    abgeschlossen: "Ja",
  },
  {
    id: "4",
    datum: "Apr 26, 2022",
    produktID: "5885322-4",
    coupon: "23",
    menge: "€327.94",
    IBAN: "AT711288283106581568",
    abgeschlossen: "Nein",
  },
  {
    id: "5",
    datum: "Nov 24, 2021",
    produktID: "22685190-9",
    coupon: "23",
    menge: "€600.72",
    IBAN: "GL0991942505171442",
    abgeschlossen: "Nein",
  },
  {
    id: "6",
    datum: "Jan 31, 2022",
    produktID: "15868195-1",
    coupon: "19",
    menge: "€36.76",
    IBAN: "GB77DEKL96820234513821",
    abgeschlossen: "Nein",
  },
  {
    id: "7",
    datum: "Apr 5, 2023",
    produktID: "3779187-3",
    coupon: "41",
    menge: "€296.06",
    IBAN: "BG22FVUS22432012850837",
    abgeschlossen: "Ja",
  },
  {
    id: "8",
    datum: "Oct 9, 2021",
    produktID: "17126666-1",
    coupon: "96",
    menge: "€700.23",
    IBAN: "BH46279830157370364189",
    abgeschlossen: "Ja",
  },
  {
    id: "9",
    datum: "Aug 3, 2022",
    produktID: "35739293-4",
    coupon: "2",
    menge: "€376.27",
    IBAN: "MD9163745702564479417878",
    abgeschlossen: "Nein",
  },
  {
    id: "10",
    datum: "Feb 25, 2022",
    produktID: "17189557-K",
    coupon: "33",
    menge: "€342.06",
    IBAN: "TN0244152076361121035143",
    abgeschlossen: "Nein",
  },
  {
    id: "11",
    datum: "Jul 8, 2021",
    produktID: "44514318-9",
    coupon: "26",
    menge: "€662.64",
    IBAN: "IS255322456418092841714063",
    abgeschlossen: "Ja",
  },
  {
    id: "12",
    datum: "Jun 28, 2021",
    produktID: "4370371-4",
    coupon: "65",
    menge: "€352.70",
    IBAN: "PL12682267765633443497193821",
    abgeschlossen: "Nein",
  },
  {
    id: "13",
    datum: "Feb 20, 2023",
    produktID: "6999783-K",
    coupon: "82",
    menge: "€299.88",
    IBAN: "MK40183972396219281",
    abgeschlossen: "Nein",
  },
  {
    id: "14",
    datum: "Sep 8, 2021",
    produktID: "40811912-K",
    coupon: "19",
    menge: "€265.29",
    IBAN: "FI8569587232624820",
    abgeschlossen: "Nein",
  },
  {
    id: "15",
    datum: "Jul 11, 2022",
    produktID: "39702555-1",
    coupon: "17",
    menge: "€145.82",
    IBAN: "BG13RBPW38216348188321",
    abgeschlossen: "Nein",
  },
  {
    id: "16",
    datum: "Nov 6, 2021",
    produktID: "2327676-3",
    coupon: "74",
    menge: "€574.48",
    IBAN: "LB08643748548956608191857173",
    abgeschlossen: "Ja",
  },
  {
    id: "17",
    datum: "Mar 5, 2022",
    produktID: "29992219-7",
    coupon: "93",
    menge: "€595.83",
    IBAN: "CZ6382604725895660966934",
    abgeschlossen: "Nein",
  },
  {
    id: "18",
    datum: "May 1, 2023",
    produktID: "50465800-7",
    coupon: "25",
    menge: "€159.97",
    IBAN: "CH8375887487336354733",
    abgeschlossen: "Nein",
  },
  {
    id: "19",
    datum: "Sep 29, 2022",
    produktID: "9893953-9",
    coupon: "98",
    menge: "€387.30",
    IBAN: "GB92OIZZ04742942158386",
    abgeschlossen: "Nein",
  },
  {
    id: "20",
    datum: "Oct 4, 2021",
    produktID: "2739272-5",
    coupon: "78",
    menge: "€723.28",
    IBAN: "PK1602604552544844345140",
    abgeschlossen: "Ja",
  },
  {
    id: "21",
    datum: "Jan 16, 2023",
    produktID: "11513335-7",
    coupon: "84",
    menge: "€858.33",
    IBAN: "IE96IRHQ68419359116136",
    abgeschlossen: "Ja",
  },
  {
    id: "22",
    datum: "May 14, 2022",
    produktID: "46863500-3",
    coupon: "83",
    menge: "€375.95",
    IBAN: "GL6011588212649595",
    abgeschlossen: "Ja",
  },
  {
    id: "23",
    datum: "Mar 5, 2023",
    produktID: "1174310-2",
    coupon: "39",
    menge: "€718.59",
    IBAN: "HR1937358315566115389",
    abgeschlossen: "Nein",
  },
  {
    id: "24",
    datum: "Aug 25, 2021",
    produktID: "32813804-2",
    coupon: "63",
    menge: "€757.58",
    IBAN: "DK9259186369348031",
    abgeschlossen: "Nein",
  },
  {
    id: "25",
    datum: "Mar 18, 2023",
    produktID: "22283740-5",
    coupon: "55",
    menge: "€395.92",
    IBAN: "CR7218228978212896577",
    abgeschlossen: "Nein",
  },
  {
    id: "26",
    datum: "Jul 16, 2022",
    produktID: "5460342-8",
    coupon: "84",
    menge: "€347.59",
    IBAN: "LI5644413197061413741",
    abgeschlossen: "Ja",
  },
  {
    id: "27",
    datum: "Oct 16, 2021",
    produktID: "10790567-7",
    coupon: "11",
    menge: "€615.76",
    IBAN: "MU2424374725858264109472314685",
    abgeschlossen: "Nein",
  },
  {
    id: "28",
    datum: "Nov 7, 2022",
    produktID: "38730806-7",
    coupon: "10",
    menge: "€474.06",
    IBAN: "LB38567194283553128542341125",
    abgeschlossen: "Nein",
  },
  {
    id: "29",
    datum: "Jun 28, 2022",
    produktID: "33940441-0",
    coupon: "90",
    menge: "€466.21",
    IBAN: "BA770166724890871825",
    abgeschlossen: "Ja",
  },
  {
    id: "30",
    datum: "Dec 16, 2021",
    produktID: "45324263-3",
    coupon: "14",
    menge: "€798.99",
    IBAN: "RO36WFEX3212264711014789",
    abgeschlossen: "Nein",
  },
  {
    id: "31",
    datum: "Jul 22, 2022",
    produktID: "29760406-6",
    coupon: "2",
    menge: "€449.78",
    IBAN: "LT631646497711266722",
    abgeschlossen: "Nein",
  },
  {
    id: "32",
    datum: "Jul 1, 2021",
    produktID: "26606468-3",
    coupon: "31",
    menge: "€439.63",
    IBAN: "IT963AXPLI95223307857846534",
    abgeschlossen: "Ja",
  },
  {
    id: "33",
    datum: "Feb 10, 2022",
    produktID: "41868413-5",
    coupon: "79",
    menge: "€916.95",
    IBAN: "AZ96324564771651962645131083",
    abgeschlossen: "Ja",
  },
  {
    id: "34",
    datum: "Dec 18, 2021",
    produktID: "20949145-1",
    coupon: "61",
    menge: "€661.87",
    IBAN: "BE21883713630224",
    abgeschlossen: "Ja",
  },
  {
    id: "35",
    datum: "Jul 16, 2021",
    produktID: "22247601-1",
    coupon: "98",
    menge: "€473.25",
    IBAN: "SI27720952253447572",
    abgeschlossen: "Nein",
  },
  {
    id: "36",
    datum: "Nov 13, 2022",
    produktID: "3122332-6",
    coupon: "18",
    menge: "€315.76",
    IBAN: "DK1945023839617989",
    abgeschlossen: "Nein",
  },
  {
    id: "37",
    datum: "Sep 26, 2022",
    produktID: "30754402-4",
    coupon: "83",
    menge: "€581.96",
    IBAN: "SE4273731139479131857744",
    abgeschlossen: "Nein",
  },
  {
    id: "38",
    datum: "Jul 23, 2022",
    produktID: "3894087-2",
    coupon: "72",
    menge: "€206.22",
    IBAN: "LT406677430695383307",
    abgeschlossen: "Nein",
  },
  {
    id: "39",
    datum: "Oct 9, 2021",
    produktID: "36828711-3",
    coupon: "69",
    menge: "€204.02",
    IBAN: "CR4956832440022859112",
    abgeschlossen: "Ja",
  },
  {
    id: "40",
    datum: "Apr 30, 2022",
    produktID: "5914546-0",
    coupon: "21",
    menge: "€493.38",
    IBAN: "MD1007466758230214268965",
    abgeschlossen: "Nein",
  },
  {
    id: "41",
    datum: "Dec 31, 2022",
    produktID: "42239734-5",
    coupon: "26",
    menge: "€697.33",
    IBAN: "EE827952963678430831",
    abgeschlossen: "Ja",
  },
  {
    id: "42",
    datum: "Dec 23, 2021",
    produktID: "1035887-6",
    coupon: "50",
    menge: "€782.31",
    IBAN: "MR5137609287657238952744136",
    abgeschlossen: "Ja",
  },
  {
    id: "43",
    datum: "Mar 25, 2022",
    produktID: "24856222-6",
    coupon: "83",
    menge: "€187.80",
    IBAN: "LT922734676957606957",
    abgeschlossen: "Nein",
  },
  {
    id: "44",
    datum: "May 24, 2022",
    produktID: "11840903-5",
    coupon: "22",
    menge: "€289.30",
    IBAN: "TN8601587842548178778726",
    abgeschlossen: "Ja",
  },
  {
    id: "45",
    datum: "Jan 17, 2022",
    produktID: "20663319-0",
    coupon: "8",
    menge: "€350.19",
    IBAN: "PL53444932575607683644926450",
    abgeschlossen: "Ja",
  },
  {
    id: "46",
    datum: "Nov 2, 2021",
    produktID: "7203847-9",
    coupon: "83",
    menge: "€890.51",
    IBAN: "CZ1108532574215173173832",
    abgeschlossen: "Ja",
  },
  {
    id: "47",
    datum: "Nov 9, 2022",
    produktID: "34104555-K",
    coupon: "73",
    menge: "€823.48",
    IBAN: "CZ5706874434236157627879",
    abgeschlossen: "Nein",
  },
  {
    id: "48",
    datum: "Jul 24, 2022",
    produktID: "44268428-6",
    coupon: "21",
    menge: "€472.65",
    IBAN: "LU666881630466211142",
    abgeschlossen: "Ja",
  },
  {
    id: "49",
    datum: "Aug 10, 2022",
    produktID: "720365-9",
    coupon: "77",
    menge: "€402.41",
    IBAN: "GR9433491388342634801265068",
    abgeschlossen: "Nein",
  },
  {
    id: "50",
    datum: "Oct 23, 2022",
    produktID: "44772979-2",
    coupon: "68",
    menge: "€263.86",
    IBAN: "FO4957552167128460",
    abgeschlossen: "Nein",
  },
  {
    id: "51",
    datum: "Jul 15, 2022",
    produktID: "48366992-5",
    coupon: "91",
    menge: "€121.52",
    IBAN: "DK7956286429295574",
    abgeschlossen: "Ja",
  },
  {
    id: "52",
    datum: "May 28, 2023",
    produktID: "1171301-7",
    coupon: "52",
    menge: "€64.64",
    IBAN: "LU482548070225953795",
    abgeschlossen: "Ja",
  },
  {
    id: "53",
    datum: "Apr 18, 2022",
    produktID: "49474934-3",
    coupon: "100",
    menge: "€120.64",
    IBAN: "SI88559722265030855",
    abgeschlossen: "Ja",
  },
  {
    id: "54",
    datum: "Jul 12, 2022",
    produktID: "8788390-6",
    coupon: "82",
    menge: "€552.96",
    IBAN: "FI8357898347488684",
    abgeschlossen: "Nein",
  },
  {
    id: "55",
    datum: "Oct 8, 2022",
    produktID: "13266989-9",
    coupon: "93",
    menge: "€276.03",
    IBAN: "LI4475374368433107557",
    abgeschlossen: "Ja",
  },
  {
    id: "56",
    datum: "Dec 7, 2022",
    produktID: "35126509-4",
    coupon: "70",
    menge: "€19.07",
    IBAN: "PK7010657831157304138452",
    abgeschlossen: "Nein",
  },
  {
    id: "57",
    datum: "Aug 22, 2021",
    produktID: "42909429-1",
    coupon: "35",
    menge: "€382.15",
    IBAN: "HU76615275594936218588122821",
    abgeschlossen: "Ja",
  },
  {
    id: "58",
    datum: "Nov 4, 2022",
    produktID: "287956-5",
    coupon: "79",
    menge: "€52.94",
    IBAN: "KW8244157533948321865238706785",
    abgeschlossen: "Ja",
  },
  {
    id: "59",
    datum: "Feb 11, 2023",
    produktID: "40909672-7",
    coupon: "53",
    menge: "€403.47",
    IBAN: "SE7273631442181486588208",
    abgeschlossen: "Ja",
  },
  {
    id: "60",
    datum: "Aug 15, 2022",
    produktID: "21916773-3",
    coupon: "96",
    menge: "€462.44",
    IBAN: "AT749358275655464745",
    abgeschlossen: "Nein",
  },
  {
    id: "61",
    datum: "Oct 21, 2021",
    produktID: "2145097-9",
    coupon: "56",
    menge: "€606.87",
    IBAN: "SE0257867362637578887451",
    abgeschlossen: "Ja",
  },
  {
    id: "62",
    datum: "May 13, 2022",
    produktID: "27259917-3",
    coupon: "88",
    menge: "€338.83",
    IBAN: "BG25RILA71362238432699",
    abgeschlossen: "Nein",
  },
  {
    id: "63",
    datum: "Oct 2, 2021",
    produktID: "27357688-6",
    coupon: "12",
    menge: "€729.38",
    IBAN: "EE386290857184464832",
    abgeschlossen: "Ja",
  },
  {
    id: "64",
    datum: "Apr 17, 2023",
    produktID: "377383-3",
    coupon: "66",
    menge: "€783.79",
    IBAN: "GB92FSUS06526872718928",
    abgeschlossen: "Nein",
  },
  {
    id: "65",
    datum: "Sep 24, 2021",
    produktID: "36127258-7",
    coupon: "74",
    menge: "€833.56",
    IBAN: "IS921333461645388739333812",
    abgeschlossen: "Nein",
  },
  {
    id: "66",
    datum: "Sep 28, 2021",
    produktID: "2157642-5",
    coupon: "15",
    menge: "€520.17",
    IBAN: "HU25465427172301217586523418",
    abgeschlossen: "Nein",
  },
  {
    id: "67",
    datum: "Aug 15, 2022",
    produktID: "20172812-6",
    coupon: "43",
    menge: "€860.88",
    IBAN: "BH68372115424771274961",
    abgeschlossen: "Ja",
  },
  {
    id: "68",
    datum: "May 3, 2022",
    produktID: "18410175-0",
    coupon: "63",
    menge: "€200.35",
    IBAN: "NO2450103818172",
    abgeschlossen: "Nein",
  },
  {
    id: "69",
    datum: "Jul 27, 2022",
    produktID: "2185851-K",
    coupon: "14",
    menge: "€546.13",
    IBAN: "AZ20642904255764424021441614",
    abgeschlossen: "Ja",
  },
  {
    id: "70",
    datum: "Dec 10, 2022",
    produktID: "29153596-8",
    coupon: "29",
    menge: "€586.37",
    IBAN: "KW7706002533173516357845555652",
    abgeschlossen: "Nein",
  },
  {
    id: "71",
    datum: "Mar 9, 2023",
    produktID: "2980360-9",
    coupon: "10",
    menge: "€431.09",
    IBAN: "NO4404618765216",
    abgeschlossen: "Nein",
  },
  {
    id: "72",
    datum: "Sep 15, 2022",
    produktID: "24318171-2",
    coupon: "45",
    menge: "€423.06",
    IBAN: "BG17WPJW71117593306368",
    abgeschlossen: "Ja",
  },
  {
    id: "73",
    datum: "Oct 27, 2022",
    produktID: "39729960-0",
    coupon: "16",
    menge: "€685.43",
    IBAN: "LI2524556115713825368",
    abgeschlossen: "Nein",
  },
  {
    id: "74",
    datum: "Mar 20, 2022",
    produktID: "33876700-5",
    coupon: "51",
    menge: "€844.34",
    IBAN: "MR9224524518685791705255308",
    abgeschlossen: "Nein",
  },
  {
    id: "75",
    datum: "Oct 31, 2022",
    produktID: "10656286-5",
    coupon: "60",
    menge: "€160.33",
    IBAN: "SA7860517419439752271921",
    abgeschlossen: "Nein",
  },
  {
    id: "76",
    datum: "Oct 20, 2021",
    produktID: "40364898-1",
    coupon: "57",
    menge: "€269.37",
    IBAN: "SI81077162992655116",
    abgeschlossen: "Ja",
  },
  {
    id: "77",
    datum: "Dec 30, 2021",
    produktID: "45434549-5",
    coupon: "7",
    menge: "€120.97",
    IBAN: "FO1798650334077338",
    abgeschlossen: "Nein",
  },
  {
    id: "78",
    datum: "Dec 20, 2021",
    produktID: "19983157-7",
    coupon: "36",
    menge: "€428.58",
    IBAN: "PK4620261677113728521871",
    abgeschlossen: "Ja",
  },
  {
    id: "79",
    datum: "Jan 20, 2023",
    produktID: "41764754-6",
    coupon: "23",
    menge: "€565.87",
    IBAN: "LT064504124144226074",
    abgeschlossen: "Nein",
  },
  {
    id: "80",
    datum: "Feb 11, 2022",
    produktID: "1742233-2",
    coupon: "57",
    menge: "€38.77",
    IBAN: "MC8221710217616466253621489",
    abgeschlossen: "Ja",
  },
  {
    id: "81",
    datum: "May 29, 2023",
    produktID: "22713430-5",
    coupon: "20",
    menge: "€266.98",
    IBAN: "IS493838641211418546778168",
    abgeschlossen: "Nein",
  },
  {
    id: "82",
    datum: "Sep 5, 2022",
    produktID: "32698136-2",
    coupon: "97",
    menge: "€910.66",
    IBAN: "MR9158111634661339738755154",
    abgeschlossen: "Nein",
  },
  {
    id: "83",
    datum: "May 25, 2022",
    produktID: "21403800-5",
    coupon: "30",
    menge: "€347.59",
    IBAN: "EE085606981605317731",
    abgeschlossen: "Ja",
  },
  {
    id: "84",
    datum: "Dec 29, 2022",
    produktID: "3150891-6",
    coupon: "31",
    menge: "€744.63",
    IBAN: "AZ52855262974034682457771614",
    abgeschlossen: "Nein",
  },
  {
    id: "85",
    datum: "Oct 31, 2021",
    produktID: "47993119-4",
    coupon: "22",
    menge: "€685.58",
    IBAN: "PK8631089888043888858026",
    abgeschlossen: "Nein",
  },
  {
    id: "86",
    datum: "Oct 7, 2021",
    produktID: "2482113-7",
    coupon: "88",
    menge: "€366.71",
    IBAN: "CH2635496567231018033",
    abgeschlossen: "Ja",
  },
  {
    id: "87",
    datum: "Nov 6, 2022",
    produktID: "46553467-2",
    coupon: "52",
    menge: "€699.98",
    IBAN: "KZ504532214925025167",
    abgeschlossen: "Nein",
  },
  {
    id: "88",
    datum: "Aug 16, 2022",
    produktID: "299770-3",
    coupon: "55",
    menge: "€397.66",
    IBAN: "GB03DADD90543844437156",
    abgeschlossen: "Nein",
  },
  {
    id: "89",
    datum: "Nov 13, 2021",
    produktID: "38717124-K",
    coupon: "33",
    menge: "€747.70",
    IBAN: "GB38YIPV45582351688445",
    abgeschlossen: "Nein",
  },
  {
    id: "90",
    datum: "May 24, 2023",
    produktID: "4045029-7",
    coupon: "64",
    menge: "€393.09",
    IBAN: "IS501068276176483682098692",
    abgeschlossen: "Ja",
  },
  {
    id: "91",
    datum: "Jan 14, 2023",
    produktID: "6704787-7",
    coupon: "18",
    menge: "€236.30",
    IBAN: "GB97KWWL36617954986842",
    abgeschlossen: "Ja",
  },
  {
    id: "92",
    datum: "Sep 20, 2021",
    produktID: "36707176-1",
    coupon: "92",
    menge: "€677.45",
    IBAN: "AL14483245682744387410179623",
    abgeschlossen: "Ja",
  },
  {
    id: "93",
    datum: "Apr 14, 2022",
    produktID: "46499261-8",
    coupon: "82",
    menge: "€733.91",
    IBAN: "LT217544631749715545",
    abgeschlossen: "Ja",
  },
  {
    id: "94",
    datum: "Dec 26, 2021",
    produktID: "42220105-K",
    coupon: "77",
    menge: "€129.85",
    IBAN: "GT35572717666123948161872296",
    abgeschlossen: "Ja",
  },
  {
    id: "95",
    datum: "Dec 29, 2022",
    produktID: "4330724-K",
    coupon: "28",
    menge: "€212.68",
    IBAN: "TN7163938215730486896264",
    abgeschlossen: "Nein",
  },
  {
    id: "96",
    datum: "Nov 25, 2022",
    produktID: "13738934-7",
    coupon: "97",
    menge: "€742.02",
    IBAN: "AZ84565778171888713135735838",
    abgeschlossen: "Nein",
  },
  {
    id: "97",
    datum: "Oct 3, 2021",
    produktID: "1068863-9",
    coupon: "10",
    menge: "€68.81",
    IBAN: "DK6585710242512280",
    abgeschlossen: "Ja",
  },
  {
    id: "98",
    datum: "Jul 28, 2021",
    produktID: "3666352-9",
    coupon: "87",
    menge: "€465.80",
    IBAN: "FO2876895183489147",
    abgeschlossen: "Nein",
  },
  {
    id: "99",
    datum: "Apr 21, 2023",
    produktID: "37761711-8",
    coupon: "55",
    menge: "€465.64",
    IBAN: "GB96NYTK98104758191827",
    abgeschlossen: "Nein",
  },
  {
    id: "100",
    datum: "Nov 28, 2022",
    produktID: "4482233-4",
    coupon: "95",
    menge: "€806.13",
    IBAN: "ME71883493281473801274",
    abgeschlossen: "Nein",
  },
];

const InteractionDialog = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "bold", fontSize: "180%" }}
          id="draggable-dialog-title"
        >
          Verlauf der Interaktion
        </DialogTitle>
        <DialogContent>
          <Box className={classes.couponRuleDiaglogContent}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="sticky table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Datum</StyledTableCell>
                      <StyledTableCell align="right">
                        Produkt ID
                      </StyledTableCell>
                      <StyledTableCell align="right">Coupon ID</StyledTableCell>
                      <StyledTableCell align="right">Coupon</StyledTableCell>
                      <StyledTableCell align="right">Menge</StyledTableCell>
                      <StyledTableCell align="right">
                        Abgeschlossen
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.datum}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.produktID}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.IBAN}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.coupon} %
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.menge}
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {row.abgeschlossen}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions className={classes.couponRuleDiaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClose}>
            Abschließen
          </ColorButtonCancel>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InteractionDialog;
