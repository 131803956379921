import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Grid,
  styled,
  Typography,
  Button,
  Stack,
  Box,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import background from "../assets/white_background_simple.jpg";
import { useState } from "react";
import BaseIdDialog from "./BaseIdDialog";

import BaseIdDialogSelected from "./BaseIdDialogSelected";

import InteractionDialog from "./InteractionDialog";

import { useEffect } from "react";
import LogoRow from "./LogoRow";

const BMWK = require("../assets/BMWK.jpg");
const IDIDEAL = require("../assets/ID-Ideal.png");

const EECCLogo = require("../assets/eecc.png");
const useStyles = makeStyles((theme) => ({
  options: {
    height: "50px",
    width: "90%",
    marginTop: "30px",
  },
  dataGrid: {
    height: "63vh",
    width: "100%",
    margin: "40px 2vw 0 3vw",
    //backgroundColor: "rgb(248,248,248)",
    backgroundImage: "url(" + background + ")",
  },

  actionButtons: {
    float: "right",
  },
  actionButtonsContainer1: {
    height: "2vh",
    margin: "35px 2vw 0 3vw",
    width: "100%",
  },
  actionButtonsContainer2: {
    height: "2vh",
    margin: "40px 2vw 0 3vw",
    width: "100%",
  },
  icon: {
    height: "12vh",
    width: "12vh",
    marginTop: "0.3vh",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  padding: "0.7rem",

  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "white",
    color: "#0F2A47",
  },
}));
const MainPageBDR = (props) => {
  const classes = useStyles();
  const {
    rows,
    columns,
    pageSize,
    setOpenSuccessBaseId,
    setOpenErrorBaseId,
    openBaseIdDialog,
    bdrToken,
    setOpenBaseIdDialog,
    newID,
  } = props;
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [openBaseIdDialogSelected, setOpenBaseIdDialogSelected] =
    useState(false);
  const [interaction, setOpenInteraction] = React.useState(false);

  const handleCloseBaseIdDialog = () => {
    setOpenBaseIdDialog(false);
  };
  const handleCloseBaseIdDialogSelected = () => {
    setOpenBaseIdDialogSelected(false);
  };
  const handleCloseInteractionDialog = () => {
    setOpenInteraction(false);
  };
  const handleClickOpenInteractionDialog = () => {
    setOpenInteraction(true);
  };

  /* Proof Request Modal functions and style */
  const [openModal, setModalOpen] = React.useState(false);
  const handleModalClose = () => setModalOpen(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const onSelect = (e) => {
    setSelectedIDs(e);
  };

  const handleClickSendBaseId = () => {
    setOpenBaseIdDialogSelected(true);
  };
  useEffect(() => {}, [interaction]);
  return (
    <Grid container spacing={3} className={classes.MainContainer}>
      <Grid className={classes.actionButtonsContainer1}>
        <Stack direction="row" spacing={2} className={classes.actionButtons}>
          {/* <Typography
            sx={{
              color: "white",
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              marginRight: {
                desktop1920: "1100px",
                esktop1980: "1100px",
                desktop2560: "1254px",
              },
              paddingTop: "5px",
              letterSpacing: ".07em",
            }}
          >
            Bedingung anpassen :
          </Typography> */}
        </Stack>
      </Grid>
      <Grid className={classes.actionButtonsContainer2}>
        {" "}
        <Stack direction="row" spacing={2} className={classes.actionButtons}>
          {/* <Typography
            sx={{
              color: "black",
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              marginRight: {
                desktop1920: "50vw",
                desktop1980: "1060px",
                desktop2560: "1207px",
              },
              paddingTop: "5px",
              letterSpacing: ".07em",
            }}
          >
            Für ausgewählte Kunden:
          </Typography> */}

          <ColorButton
            variant="contained"
            size="small"
            style={{ backgroundColor: "#0F2A47 !important" }}
            disabled={selectedIDs.length > 0 ? false : true}
            onClick={handleClickSendBaseId}
          >
            Perso-Credential senden
          </ColorButton>
        </Stack>
      </Grid>
      <Grid className={classes.dataGrid}>
        <BaseIdDialog
          bdrToken={bdrToken}
          open={openBaseIdDialog}
          handleClose={handleCloseBaseIdDialog}
          setOpenSuccessSendBaseId={setOpenSuccessBaseId}
          setOpenErrorSendBaseId={setOpenErrorBaseId}
          newID={newID}
        />
        <BaseIdDialogSelected
          bdrToken={bdrToken}
          open={openBaseIdDialogSelected}
          handleClose={handleCloseBaseIdDialogSelected}
          setOpenSuccessSendBaseId={setOpenSuccessBaseId}
          setOpenErrorSendBaseId={setOpenErrorBaseId}
          selectedIDs={selectedIDs}
        />
        <InteractionDialog
          open={interaction}
          handleClose={handleCloseInteractionDialog}
          handleClickOpen={handleClickOpenInteractionDialog}
        />
        <DataGrid
          loading={true}
          autoPageSize
          style={{ fontSize: "80%" }}
          rows={rows}
          columns={columns}
          // pageSize={pageSize}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(e) => onSelect(e)}
        />
      </Grid>

      <Box sx={{ flexGrow: 1 }}></Box>
      <LogoRow
        classes={classes}
        logo1={EECCLogo}
        logo2={BMWK}
        logo3={IDIDEAL}
      />
    </Grid>
  );
};
export default MainPageBDR;
