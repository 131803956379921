import { makeStyles } from "@mui/styles";
import * as React from "react";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { styled, Paper, TextField, Button, Box } from "@mui/material";
import axios from "axios";
import Draggable from "react-draggable";
import urlBackend from "../config.json";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#0F2A47",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles((theme) => ({
  couponRuleDiaglogAction: {
    padding: "0rem 1.5rem 1rem !important",
  },
}));
const BaseIdDialog = (props) => {
  const {
    open,
    handleClose,
    setOpenSuccessSendBaseId,
    setOpenErrorSendBaseId,
    bdrToken,
    selectedIDs,
  } = props;

  // Credential Attributes
  const [addressCity, setAddressCity] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [nationality, setNationality] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [academicTitle, setAcademicTitle] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [firstName, setFirstName] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [familyName, setFamilyName] = useState("");

  const classes = useStyles();
  const handleSendBaseIDs = () => {
    for (let i = 0; i < selectedIDs.length; i++) {
      handleSendBaseId(selectedIDs[i]);
    }
    handleClose();
  };
  const handleSendBaseId = (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append("addressCity", addressCity);
    bodyFormData.append("dateOfExpiry", "12.09.2025");
    bodyFormData.append("addressZipCode", addressZipCode);
    bodyFormData.append("nationality", nationality);
    bodyFormData.append("placeOfBirth", placeOfBirth);
    bodyFormData.append("addressStreet", addressStreet);
    bodyFormData.append("academicTitle", academicTitle);
    bodyFormData.append("dateOfBirth", dateOfBirth);
    bodyFormData.append("documentType", "Personalausweis");
    bodyFormData.append("birthName", "");
    bodyFormData.append("firstName", firstName);
    bodyFormData.append("addressCountry", addressCountry);
    bodyFormData.append("familyName", familyName);
    bodyFormData.append("bdrToken", bdrToken);
    bodyFormData.append("connectionID", id);

    axios({
      method: "post",
      url: urlBackend.BACKEND_SERVER + "/bdr/sendBaseId",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setOpenSuccessSendBaseId(true);
        setOpenErrorSendBaseId(false);

        console.log(response);
      })
      .catch(function (response) {
        setOpenErrorSendBaseId(true);
        console.log(response);
      });
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "bold", fontSize: "180%" }}
          id="draggable-dialog-title"
        >
          Daten für Ihren digitalen Personalausweis eingeben
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-basic"
                label="Titel"
                value={academicTitle}
                onChange={(event) => setAcademicTitle(event.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Vorname"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Nachname"
                value={familyName}
                onChange={(event) => setFamilyName(event.target.value)}
              />
            </div>

            <div>
              <TextField
                required
                id="outlined-required"
                label="Geburtsort"
                value={placeOfBirth}
                onChange={(event) => setPlaceOfBirth(event.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Geburtstagsdatum"
                value={dateOfBirth}
                onChange={(event) => setDateOfBirth(event.target.value)}
              />
            </div>

            <div>
              <TextField
                required
                id="outlined-required"
                label="Wohnort"
                value={addressCity}
                onChange={(event) => setAddressCity(event.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Straße + Hausnummer"
                value={addressStreet}
                onChange={(event) => setAddressStreet(event.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Postleitzahl"
                value={addressZipCode}
                onChange={(event) => setAddressZipCode(event.target.value)}
              />
            </div>

            <div>
              <TextField
                id="outlined-basic"
                label="Land"
                value={addressCountry}
                onChange={(event) => setAddressCountry(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Nationalität"
                value={nationality}
                onChange={(event) => setNationality(event.target.value)}
              />
            </div>
          </Box>
        </DialogContent>

        <DialogActions className={classes.couponRuleDiaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClose}>
            Abschließen
          </ColorButtonCancel>
          <ColorButtonConfirm onClick={handleSendBaseIDs}>
            Ausstellen
          </ColorButtonConfirm>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BaseIdDialog;
