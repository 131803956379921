import * as React from "react";
import { Button, Stack } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const SkipUseCoupon = (props) => {
  const { onClickSkipCoupon } = props;
  return (
    <Stack spacing={1} alignItems="center">
      <Stack
        direction="row"
        spacing={1}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <Button
          variant="contained"
          color="info"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          onClick={onClickSkipCoupon}
        >
          Ohne Coupon bezahlen
        </Button>
      </Stack>
    </Stack>
  );
};
export default SkipUseCoupon;
