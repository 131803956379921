import React, { useState, useEffect } from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import "./App.css";
import HeaderVendor from "./Components/HeaderVendor";
import Footer from "./Components/Footer";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import background from "./assets/blue_background_2.jpg";
import MainPageVendor from "./Components/MainPageVendor";
import Button from "@mui/material/Button";
import urlBackend from "./config.json";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    width: "100%",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundImage: "url(" + background + ")",
    //backgroundColor: "rgb(248,248,248)",
  },
}));

const App = () => {
  document.title = "Händlersicht - SSI Couponing Demo";
  const classes = useStyles();
  const [site, setSite] = useState("Vendor");

  const [columns, setColumns] = useState([
    { field: "id", headerName: "Verbindungs-ID", width: 300 },
    { field: "firstName", headerName: "Vorname", width: 100 },
    { field: "familyName", headerName: "Nachname", width: 100 },

    { field: "addressCity", headerName: "Stadt", width: 200 },
    {
      field: "addressZipCode",
      headerName: "PLZ",
      width: 100,
    },
    {
      field: "addressCountry",
      headerName: "Land",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      width: 200,
    },
  ]);
  const [openAlertErrorCouponRule, setOpenAlertErrorCouponRule] =
    useState(false);
  const [openAlertSuccessCouponRule, setOpenAlertSuccessCouponRule] =
    useState(false);
  const [openAlertSuccessCredential, setOpenAlertSuccessCredential] =
    useState(false);
  const [loadingDataGrid, setLoadingDataGrid] = useState(true);
  const [openAlertErrorCredential, setOpenAlertErrorCredential] =
    useState(false);

  const [openAlertErrorCoupon, setOpenAlertErrorCoupon] = useState(false);
  const [openAlertSuccessCoupon, setOpenAlertSuccessCoupon] = useState(false);

  const [vendorConnectionID, setVendorConnectionID] = useState("");
  const [tokenVendor, setTokenVendor] = useState("");
  const [vendorWalletID, setVendorWalletID] = useState("");
  const [allconnectionsIDs, setAllconnectionsIDs] = useState([]);
  const [walletRows, setWalletRows] = useState([]);
  const [openAlertNewID, setOpenAlNewID] = useState(false);
  const [newID, setNewID] = useState("");
  const [openCouponDialog, setOpenCouponDialog] = useState(false);
  const [openSuccessSendProofRequest, setOpenSuccessSendProofRequest] =
    useState(false);
  const [openErrorSendProofRequest, setOpenErrorSendProofRequest] =
    useState(false);
  const [stateVendor, setStateVendor] = useState("");
  const [openAlertReadyForCoupon, setOpenAlertReadyForCoupon] = useState(false);
  const handleCloseReadyForCoupon = () => {
    setOpenAlertReadyForCoupon(false);
  };
  const handleCloseErrorCredential = () => {
    setOpenAlertErrorCredential(false);
  };
  const handleCloseErrorCoupon = () => {
    setOpenAlertErrorCoupon(false);
  };
  const handleCloseErrorCouponRule = () => {
    setOpenAlertErrorCouponRule(false);
  };
  const handleCloseSuccessCredential = () => {
    setOpenAlertSuccessCredential(false);
  };
  const handleCloseSuccessCoupon = () => {
    setOpenAlertSuccessCoupon(false);
  };
  const handleCloseSuccessCouponRule = () => {
    setOpenAlertSuccessCouponRule(false);
  };
  const handleCloseSuccessProofRequest = () => {
    setOpenSuccessSendProofRequest(false);
  };
  const handleCloseErrorProofRequest = () => {
    setOpenErrorSendProofRequest(false);
  };
  const handleDelete = async (connectionID) => {
    var bodyFormData = new FormData();
    bodyFormData.append("token", tokenVendor);
    bodyFormData.append("connectionID", connectionID);

    await axios({
      method: "post",
      url: urlBackend.BACKEND_SERVER + "/deleteConnection",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.log("response from /deleteConnection: ");
      console.log(response.data);
      return response;
    });
  };
  /* Get Wallet Vendor Credential */
  /* useEffect(() => {
    const getTokens = () => {
      if (tokenVendor === "") {
        axios
          .get(urlBackend.BACKEND_SERVER + "/vendor/getWalletCredential")
          .then((response) => {
            setOpenAlertErrorCredential(false);
            setOpenAlertSuccessCredential(true);

            setTokenVendor(response.data.walletToken);
            setVendorWalletID(response.data.walletID);
            console.log("reponse from /vendor/getWalletCredential: ");
            console.log(response.data);
          })
          .catch((error) => {
            setOpenAlertErrorCredential(true);
          });
      }
    };
    const getCurrentTokenFunction = setInterval(() => getTokens(), 50000);
    return () => clearInterval(getCurrentTokenFunction);
  }, [tokenVendor]); */
  /* Get Wallet Vendor Credential */
  useEffect(() => {
    if (tokenVendor === "") {
      axios
        .get(urlBackend.BACKEND_SERVER + "/vendor/getWalletCredential")
        .then((response) => {
          setOpenAlertErrorCredential(false);
          setOpenAlertSuccessCredential(true);

          setTokenVendor(response.data.walletToken);
          setVendorWalletID(response.data.walletID);
          console.log("reponse from /vendor/getWalletCredential: ");
          console.log(response.data);
        })
        .catch((error) => {
          setOpenAlertErrorCredential(true);
        });
    }
  }, [tokenVendor]);
  /* Check Vendor Connections */
  useEffect(() => {
    const getAllConnectionsIDs = async () => {
      setLoadingDataGrid(true);
      if (tokenVendor !== "") {
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("tokenVendor", tokenVendor);
        bodyFormDataVendor.append("state", ["response", "active"]);

        await axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/getAllConnections",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          console.log("response from /vendor/getAllConnections: ");
          console.log(response.data);
          /* const unfilteredConnections = response.data.walletAllConnectionIDs;
          const filteredConnections = unfilteredConnections.filter(
            (entry) => entry.state === "response" || entry.state === "active"
          );
          console.log(filteredConnections);
          setAllconnectionsIDs(filteredConnections); */

          setAllconnectionsIDs(response.data.walletAllConnectionIDs);
          setLoadingDataGrid(false);
        });
      }
    };
    const getCurrentTokenFunction = setInterval(
      () => getAllConnectionsIDs(),
      10000
    );
    return () => clearInterval(getCurrentTokenFunction);
  }, [tokenVendor]);
  useEffect(() => {
    var connArray = [];
    allconnectionsIDs.map((e) => {
      let date = new Date(e.createdAt).toUTCString();
      connArray.push({
        id: e.id,
        familyName: e.familyName,
        firstName: e.firstName,
        addressCountry: e.addressCountry,
        addressZipCode: e.addressZipCode,
        addressCity: e.addressCity,
        createdAt: e.createdAt,
      });
    });

    //console.log(allconnectionsIDs);
    console.log(connArray);
    //console.log(walletRows);
    let newObject = getDifference(connArray, walletRows);
    console.log("new IDs ", newObject[newObject.length - 1]);

    if (newObject[newObject.length - 1] !== undefined) {
      setOpenAlNewID(true);
      setNewID(newObject[newObject.length - 1].id);
      console.log("new ID ", newObject[newObject.length - 1].id);
    }

    connArray.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    console.log(connArray);

    setWalletRows(connArray);
  }, [allconnectionsIDs]);
  function getDifference(array1, array2) {
    return array1.filter(
      ({ id: id1 }) => !array2.some(({ id: id2 }) => id2 === id1)
    );
  }

  const onClickSendProofRequest = (connectionIDs) => {
    console.log("onClickSendProofRequest");
    if (Array.isArray(connectionIDs)) {
      console.log("onClickSendProofRequest Array");

      for (let i = 0; i < connectionIDs.length; i++) {
        console.log("send proof Request to " + connectionIDs[i]);
        var bodyFormData = new FormData();
        bodyFormData.append("vendorToken", tokenVendor);
        bodyFormData.append("connectionID", connectionIDs[i]);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/askForBaseIDProofRequest",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            setOpenSuccessSendProofRequest(true);
            setOpenErrorSendProofRequest(false);

            console.log(response);
          })
          .catch(function (response) {
            setOpenErrorSendProofRequest(true);
            console.log(response);
          });
      }
    } else {
      console.log("onClickSendProofRequest String");

      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", tokenVendor);
      bodyFormData.append("connectionID", connectionIDs);
      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/askForBaseIDProofRequest",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setOpenSuccessSendProofRequest(true);
          setOpenErrorSendProofRequest(false);

          console.log(response);
        })
        .catch(function (response) {
          setOpenErrorSendProofRequest(true);
          console.log(response);
        });
    }
    setOpenAlNewID(false);
  };

  useEffect(() => {
    const getCurrentState = () => {
      if (newID !== "") {
        var bodyFormDataVendor = new FormData();
        bodyFormDataVendor.append("vendorToken", tokenVendor);
        bodyFormDataVendor.append("vendorConnectionID", newID);
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/vendor/getCurrentStateVendor",
          data: bodyFormDataVendor,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          setStateVendor(response.data);
          console.log("response from /vendor/getCurrentStateVendor: ");
          console.log(response.data);
        });
      }
    };
    const getCurrentStateFunction = setInterval(() => getCurrentState(), 3000);
    return () => clearInterval(getCurrentStateFunction);
  }, [newID, tokenVendor, vendorConnectionID]);
  useEffect(() => {
    if (stateVendor === "active") {
      setOpenAlertReadyForCoupon(true);
    }
  }, [stateVendor]);
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.root}>
      <HeaderVendor />
      <Snackbar
        open={openAlertNewID}
        autoHideDuration={20000}
        onClose={() => setOpenAlNewID(false)}
        style={{ margin: "10vh auto", fontFamily: "monospace" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenAlNewID(false)}>
          Neue Verbindung: {newID}
        </Alert>
        {/*
          action={
            <>
              <Button
                onClick={() => onClickSendProofRequest(newID)}
                color="inherit"
                size="medium"
                style={{ fontFamily: "monospace", padding: "2px" }}
              >
                Daten anfragen
              </Button>
            </>
          }
        style={{ fontFamily: "monospace" }} 
        */}
      </Snackbar>
      {/*
      <Snackbar
        open={openAlertReadyForCoupon}
        autoHideDuration={10000}
        onClose={handleCloseReadyForCoupon}
        style={{ margin: "10vh auto", fontFamily: "monospace" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseReadyForCoupon}
          severity="success"
          sx={{ width: "fit-content" }}
          action={
            <>
              <Button
                onClick={() => setOpenCouponDialog(true)}
                color="inherit"
                size="medium"
                style={{ fontFamily: "monospace", padding: "2px" }}
              >
                Send Coupon
              </Button>
            </>
          }
        >
          Diese Verbindung-ID {newID} warte gerade auf das Coupon!
        </Alert>
      </Snackbar>*/}
      <MainPageVendor
        rows={walletRows}
        columns={columns}
        site={site}
        loadingDataGrid={loadingDataGrid}
        /** for Coupon with Rule */
        openSuccessCouponRule={openAlertSuccessCouponRule}
        openErrorCouponRule={openAlertErrorCouponRule}
        setOpenSuccessCouponRule={setOpenAlertSuccessCouponRule}
        setOpenErrorCouponRule={setOpenAlertErrorCouponRule}
        /** for Coupon */
        setOpenSuccessCoupon={setOpenAlertSuccessCoupon}
        setOpenErrorCoupon={setOpenAlertErrorCoupon}
        openAlertNewID={openAlertNewID}
        newID={newID}
        vendorToken={tokenVendor}
        onClickSendProofRequest={onClickSendProofRequest}
        handleDelete={handleDelete}
        openCouponDialog={openCouponDialog}
        setOpenCouponDialog={setOpenCouponDialog}
        allConnections={allconnectionsIDs}
      />
      <Snackbar
        open={openAlertSuccessCoupon}
        autoHideDuration={3000}
        onClose={handleCloseSuccessCoupon}
        style={{ marginLeft: "40vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseSuccessCoupon}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Das Coupon-Credential wurde erfolgreich ausgestellt!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccessCouponRule}
        autoHideDuration={3000}
        onClose={handleCloseSuccessCouponRule}
        style={{ marginLeft: "35vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseSuccessCouponRule}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Das Coupon-Credential wurde erfolgreich gesendet!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessSendProofRequest}
        autoHideDuration={3000}
        onClose={handleCloseSuccessProofRequest}
        style={{ marginLeft: "35vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseSuccessProofRequest}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Die Datenanfrage wurde erfolgreich gesendet!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertErrorCouponRule}
        autoHideDuration={3000}
        onClose={handleCloseErrorCouponRule}
        style={{ marginLeft: "35vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorCouponRule}
          severity="error"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Fehler beim erstellen des Coupons!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSendProofRequest}
        autoHideDuration={3000}
        onClose={handleCloseErrorProofRequest}
        style={{ marginLeft: "35vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorProofRequest}
          severity="error"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Fehler bei der Datenanfrage!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertErrorCoupon}
        autoHideDuration={3000}
        onClose={handleCloseErrorCoupon}
        style={{ marginLeft: "35vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorCoupon}
          severity="error"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Ein Fehler ist beim Ausstellen des Coupon-Credentials aufgetreten!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccessCredential}
        autoHideDuration={3000}
        onClose={handleCloseSuccessCredential}
        style={{ fontFamily: "monospace" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSuccessCredential}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Erfolgreich mit dem Server verbunden!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertErrorCredential}
        autoHideDuration={3000}
        onClose={handleCloseErrorCredential}
        style={{
          marginLeft: "35vw",
          fontFamily: "monospace",
          fontFamily: "monospace",
        }}
      >
        <Alert
          onClose={handleCloseErrorCredential}
          severity="error"
          sx={{ width: "fit-content" }}
        >
          Fehler beim Verbinden mit dem Server!
        </Alert>
      </Snackbar>

      <Footer />
    </Box>
  );
};

export default App;
