import React, { useState, useEffect } from "react";
import { Box, Snackbar, Alert, Button } from "@mui/material";
import "./App.css";
import HeaderBDR from "./Components/HeaderBDR";
import Footer from "./Components/Footer";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import background from "./assets/blue_background_2.jpg";
import MainPageBDR from "./Components/MainPageBDR";
import urlBackend from "./config.json";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    width: "100%",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundImage: "url(" + background + ")",
    //backgroundColor: "rgb(248,248,248)",
  },
}));

const AppBDR = () => {
  const classes = useStyles();
  const handleSendBaseId = (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append("addressCity", "addressCity");
    bodyFormData.append("dateOfExpiry", "12.09.2025");
    bodyFormData.append("addressZipCode", "40225");
    bodyFormData.append("nationality", "nationality");
    bodyFormData.append("placeOfBirth", "placeOfBirth");
    bodyFormData.append("addressStreet", "addressStreet");
    bodyFormData.append("academicTitle", "academicTitle");
    bodyFormData.append("dateOfBirth", "dateOfBirth");
    bodyFormData.append("documentType", "Personalausweis");
    bodyFormData.append("birthName", "");
    bodyFormData.append("firstName", "firstName");
    bodyFormData.append("addressCountry", "addressCountry");
    bodyFormData.append("familyName", "familyName");
    bodyFormData.append("bdrToken", "bdrToken");
    bodyFormData.append("connectionID", newID);

    axios({
      method: "post",
      url: urlBackend.BACKEND_SERVER + "/bdr/sendBaseId",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  const [columns, setColumns] = useState([
    { field: "id", headerName: "DID", width: 300 },
    { field: "firstName", headerName: "Vorname", width: 100 },
    { field: "familyName", headerName: "Nachname", width: 100 },

    { field: "addressCity", headerName: "Stadt", width: 200 },
    {
      field: "addressZipCode",
      headerName: "PLZ",
      width: 100,
    },
    {
      field: "addressCountry",
      headerName: "Land",
      width: 200,
    },
  ]);
  const [openAlertSuccessBaseId, setOpenAlertSuccessBaseId] = useState(false);
  const [openAlertErrorBaseId, setOpenAlertErrorBaseId] = useState(false);
  const [
    openAlertSuccessCredentialBaseId,
    setOpenAlertSuccessCredentialBaseId,
  ] = useState(false);
  const [openAlertErrorCredentialBaseId, setOpenAlertErrorCredentialBaseId] =
    useState(false);
  const handleCloseErrorCredential = () => {
    setOpenAlertErrorCredentialBaseId(false);
  };
  const handleCloseSuccessCredential = () => {
    setOpenAlertSuccessCredentialBaseId(false);
  };

  const [bdrConnectionID, setBdrConnectionID] = useState("");
  const [tokenBdr, setTokenBdr] = useState("");
  const [allconnectionsIDs, setAllconnectionsIDs] = useState([]);
  const [walletRows, setWalletRows] = useState([]);
  const [openAlertNewID, setOpenAlNewID] = useState(false);
  const [newID, setNewID] = useState("");
  const [openBaseIdDialog, setOpenBaseIdDialog] = useState(false);
  const [bdrWalletID, setBdrWalletID] = useState("");
  const handleCloseErrorBaseId = () => {
    openAlertErrorBaseId(false);
  };

  const handleCloseSuccessBaseId = () => {
    setOpenAlertSuccessBaseId(false);
  };
  const onClickSendBaseIDToNewID = () => {
    setOpenBaseIdDialog(true);
  };

  /* Get Wallet BDR Credential */
  useEffect(() => {
    const getTokens = () => {
      if (tokenBdr === "") {
        axios
          .get(urlBackend.BACKEND_SERVER + "/bdr/getWalletCredential")
          .then((response) => {
            setOpenAlertErrorCredentialBaseId(false);
            setOpenAlertSuccessCredentialBaseId(true);

            setTokenBdr(response.data.walletToken);
            setBdrWalletID(response.data.walletID);
            console.log("reponse from /bdr/getWalletCredential: ");
            console.log(response.data);
          })
          .catch((error) => {
            setOpenAlertErrorCredentialBaseId(true);
          });
      }
    };
    const getCurrentTokenFunction = setInterval(() => getTokens(), 2000);
    return () => clearInterval(getCurrentTokenFunction);
  }, [tokenBdr]);
  /* Check Bdr Connections */
  useEffect(() => {
    const getAllConnectionsIDs = () => {
      if (tokenBdr !== "") {
        var bodyFormDataBdr = new FormData();
        bodyFormDataBdr.append("tokenBdr", tokenBdr);
        // bodyFormDataBdr.append("state", "response");
        axios({
          method: "post",
          url: urlBackend.BACKEND_SERVER + "/bdr/getAllConnections",
          data: bodyFormDataBdr,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          console.log("response from /bdr/getAllConnections: ");
          console.log(response.data);
          setAllconnectionsIDs(response.data.walletAllConnectionIDs);
        });
      }
    };
    const getCurrentTokenFunction = setInterval(
      () => getAllConnectionsIDs(),
      10000
    );
    return () => clearInterval(getCurrentTokenFunction);
  }, [tokenBdr]);

  useEffect(() => {
    var connArray = [];
    allconnectionsIDs.map((e) => {
      connArray.push({
        id: e.id,
        familyName: e.familyName,
        firstName: e.firstName,
        addressCountry: e.addressCountry,
        addressZipCode: e.addressZipCode,
        addressCity: e.addressCity,
      });
    });

    let newObject = getDifference(connArray, walletRows);
    console.log("new IDs ", newObject[newObject.length - 1]);

    if (newObject[newObject.length - 1] !== undefined) {
      setOpenAlNewID(true);
      setNewID(newObject[newObject.length - 1].id);
      console.log("new ID ", newObject[newObject.length - 1].id);
    }

    setWalletRows(connArray);
  }, [allconnectionsIDs]);
  function getDifference(array1, array2) {
    return array1.filter(
      ({ id: id1 }) => !array2.some(({ id: id2 }) => id2 === id1)
    );
  }
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.root}>
      <HeaderBDR />
      <Snackbar
        open={openAlertNewID}
        autoHideDuration={20000}
        onClose={() => setOpenAlNewID(false)}
        style={{ margin: "10vh auto", fontFamily: "monospace" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => setOpenAlNewID(false)}
          action={
            <>
              {" "}
              <Button
                onClick={() => onClickSendBaseIDToNewID()}
                color="inherit"
                size="medium"
                style={{ fontFamily: "monospace", padding: "2px" }}
              >
                Send BaseId
              </Button>{" "}
            </>
          }
          style={{ fontFamily: "monospace" }}
        >
          Letzte Verbindung-ID: {newID}
        </Alert>
      </Snackbar>
      <MainPageBDR
        rows={walletRows}
        columns={columns}
        pageSize={8}
        setOpenSuccessBaseId={setOpenAlertSuccessBaseId}
        setOpenErrorBaseId={setOpenAlertErrorBaseId}
        openAlertNewID={openAlertNewID}
        openBaseIdDialog={openBaseIdDialog}
        newID={newID}
        setOpenBaseIdDialog={setOpenBaseIdDialog}
        bdrToken={tokenBdr}
      />
      <Snackbar
        open={openAlertSuccessBaseId}
        autoHideDuration={3000}
        onClose={handleCloseSuccessBaseId}
        style={{ marginLeft: "40vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseSuccessBaseId}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Das BaseId-Credential wurde erfolgreich ausgestellt!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openAlertErrorBaseId}
        autoHideDuration={3000}
        onClose={handleCloseErrorBaseId}
        style={{ marginLeft: "40vw", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorBaseId}
          severity="error"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Ein Fehler ist beim Austellen des BaseId-Credentials aufgetreten!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccessCredentialBaseId}
        autoHideDuration={3000}
        onClose={handleCloseSuccessCredential}
        style={{ fontFamily: "monospace" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSuccessCredential}
          severity="success"
          sx={{
            width: "fit-content",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          Erfolgreich mit dem Server verbunden!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertErrorCredentialBaseId}
        autoHideDuration={3000}
        onClose={handleCloseErrorCredential}
        style={{ marginLeft: "auto", fontFamily: "monospace" }}
      >
        <Alert
          onClose={handleCloseErrorCredential}
          severity="error"
          sx={{ width: "fit-content", fontFamily: "monospace" }}
        >
          Fehler beim Verbinden mit dem Server!
        </Alert>
      </Snackbar>
      <Footer />
    </Box>
  );
};

export default AppBDR;
