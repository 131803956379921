import { makeStyles } from "@mui/styles";
import * as React from "react";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { filterByPostCode, checkIfValidPostCodeRules } from "../utils";
import {
  styled,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  TableRow,
  TextField,
  Button,
  ButtonGroup,
  DialogContentText,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  TableHead,
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import axios from "axios";
import Draggable from "react-draggable";
import { useEffect } from "react";
import urlBackend from "../config.json";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#0F2A47",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const useStyles = makeStyles((theme) => ({
  couponRuleDiaglogContent: {
    width: "70vw",
    height: "35vw",
    border: "#0E86D4 solid 4px",
  },
  couponRuleDiaglogAction: {
    padding: "0rem 1.5rem 1rem !important",
  },
  bedingungsButton: {
    textTransform: "none !important",
  },

  neueRegelnButtonGruppe: {
    float: "right",
  },
  neueRegelnButton: {
    textTransform: "none !important",
    fontWeight: "bold",
  },
  deleteButton: {
    textTransform: "none !important",
    fontWeight: "bold",
    float: "right",
    margin: "10px !important",
  },
  bedingungsInhalt: {
    margin: "0.5vw",
    width: "65vw",
    height: "72px",
    float: "right",
    border: "#0E86D4 solid 1px",
    borderRadius: "5px 20px 5px",
  },
}));
const CouponRegelnDialog = (props) => {
  const {
    open,
    handleClose,
    setOpenSuccessSendCouponRule,
    setOpenErrorSendCouponRule,
    vendorToken,
    newID,
    allConnections,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [gegenwertType, setGegenwertType] = useState("");
  const [gegenwertValue, setGegenwertValue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getUTCMonth() + 1,
      new Date().getUTCDate()
    )
  );
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [allfilteredConnection, setAllfilteredConnection] = useState([]);
  const [errorValidRules, setErrorValidRules] = useState("");
  const handleCheckRulesAndConfirm = () => {
    console.log(regelnList);

    /** check if Post Code Rule present */
    let postRulePresent = false;
    regelnList.map((e) => {
      if (e.objekt === "PLZ-Bereich") {
        postRulePresent = true;
      }
    });
    console.log("postRulePresent" + postRulePresent);
    /** if Post Code Rule present */
    if (postRulePresent) {
      /** Check if Post Code Rule Error  */
      let error = checkIfValidPostCodeRules(regelnList, regelnZusammenhang);
      console.log(error);
      if (error !== "") {
        setErrorValidRules(error);
      } else {
        setErrorValidRules("");
        let filteredConnections = [];

        if (regelnZusammenhang === "und") {
          regelnList.forEach((e) => {
            if (e.objekt === "PLZ-Bereich") {
              if (e.vergleich === "==") {
                filteredConnections = filteredConnections.concat(
                  filterByPostCode(allConnections, e.werte, e.werte)
                );
                return;
              }
              if (e.vergleich === ">=") {
                filteredConnections = filteredConnections.concat(
                  filterByPostCode(allConnections, e.werte, null)
                );
              }
              if (e.vergleich === "<=") {
                filteredConnections = filteredConnections.concat(
                  filterByPostCode(allConnections, null, e.werte)
                );
              }
              if (e.vergleich === "in") {
                console.log(filterByPostCode(allConnections, e.von, e.bis));
                filteredConnections = filteredConnections.concat(
                  filterByPostCode(allConnections, e.von, e.bis)
                );
              }
            }
          });
        } else {
          let equalList = [];
          let intervallList = [];
          regelnList.forEach((e) => {
            if (e.objekt === "PLZ-Bereich") {
              if (e.vergleich === "==") {
                equalList.push(Number(e.werte));
              }
              if (e.vergleich === "in") {
                intervallList.push({ minPostCode: e.von, maxPostCode: e.bis });
              }
            }
          });
          equalList.forEach((e) => {
            let plz = filterByPostCode(allConnections, e, e);
            filteredConnections = filteredConnections.concat(plz);
          });
          intervallList.forEach((e) => {
            let plzIntervall = filterByPostCode(
              allConnections,
              e.minPostCode,
              e.maxPostCode
            );
            filteredConnections = filteredConnections.concat(plzIntervall);
          });
        }
        setAllfilteredConnection(filteredConnections);
      }
    } else {
      /** if Post Code Rule not present */
      setAllfilteredConnection(allConnections);
    }

    setConfirmDialog(true);
  };
  const handleConfirmDialog = () => {
    handleSendCouponWithRules(allfilteredConnection);
    setConfirmDialog(false);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialog(false);
  };
  const handleChangeFromARule = (properties, event, index) => {
    if (event.target) {
      console.log(event.target.value);
      console.log(index);
      console.log(properties);

      let newArr = [...regelnList]; // copying the old datas array
      newArr[index][properties] = event.target.value; // replace e.target.value with whatever you want to change it to
      console.log(newArr[index].properties);

      setRegelnList(newArr);
    }
  };
  const classes = useStyles();
  const [regelnList, setRegelnList] = useState([]);
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [regelnZusammenhang, setBedingungsbuttonZustand] = useState("und");
  const onClickAddNewRule = () => {
    setRegelnList([
      ...regelnList,
      { objekt: "", vergleich: "", werte: "", von: "", bis: "" },
    ]);
  };
  const onClickDeleteRule = (index) => {
    let newArr = [...regelnList]; // copying the old datas array
    newArr.splice(index, 1);
    setRegelnList([...newArr]);
  };

  useEffect(() => {
    console.log(regelnList);
    console.log(errorValidRules);
    console.log(allfilteredConnection);
  }, [regelnList, errorValidRules, allfilteredConnection]);

  function getRandomNumber(amount) {
    let digits = "";
    for (let i = 0; i < amount; ++i) digits += Math.floor(Math.random() * 10);

    return digits;
  }

  const handleSendCouponWithRules = (allConnectionIDsFiltedByPostCode) => {
    allConnectionIDsFiltedByPostCode.forEach((element) => {
      var bodyFormData = new FormData();
      bodyFormData.append("vendorToken", vendorToken);
      bodyFormData.append("coupon-id", getRandomNumber(10));
      bodyFormData.append("rabattPercent", gegenwertValue);
      bodyFormData.append("warengruppe", gegenwertType);
      bodyFormData.append(
        "gueltigkeitsdauer",
        startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString()
      );

      bodyFormData.append("connection-id", element.id);

      /** einloesebedingung beschreibung */
      if (regelnList !== undefined && regelnList.at(0) !== undefined) {
        let einloesebedingung = "";
        regelnList.map((e) => {
          einloesebedingung += e.objekt + e.vergleich + e.werte + "&&";
        });
        bodyFormData.append("einloesebedingung", einloesebedingung);
      } else {
        bodyFormData.append("einloesebedingung", "");
      }

      axios({
        method: "post",
        url: urlBackend.BACKEND_SERVER + "/vendor/sendCoupon",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setOpenSuccessSendCouponRule(true);
          setOpenErrorSendCouponRule(false);

          console.log(response);
        })
        .catch(function (response) {
          setOpenErrorSendCouponRule(true);
          console.log(response);
        });
    });
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontWeight: "bold", fontSize: "180%" }}
          id="draggable-dialog-title"
        >
          Hier erstellen Sie einen neuen Coupon!
        </DialogTitle>
        <DialogContent>
          <Box className={classes.couponRuleDiaglogContent}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: "120%" }}
                >
                  Bedingungen für das Einlösen
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Anzahl Bedingungen: {regelnList.length}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    size="medium"
                    variant={
                      regelnZusammenhang !== "und" ? "outlined" : "contained"
                    }
                    onClick={() => setBedingungsbuttonZustand("und")}
                    style={
                      regelnZusammenhang !== "und"
                        ? { color: "black" }
                        : { backgroundColor: "seagreen" }
                    }
                    className={classes.bedingungsButton}
                  >
                    und
                  </Button>
                  <Button
                    size="medium"
                    variant={
                      regelnZusammenhang !== "oder" ? "outlined" : "contained"
                    }
                    onClick={() => setBedingungsbuttonZustand("oder")}
                    style={
                      regelnZusammenhang !== "oder"
                        ? { color: "black" }
                        : { backgroundColor: "seagreen" }
                    }
                    className={classes.bedingungsButton}
                  >
                    oder
                  </Button>
                </ButtonGroup>
                <ButtonGroup className={classes.neueRegelnButtonGruppe}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="info"
                    onClick={() => onClickAddNewRule()}
                    className={classes.neueRegelnButton}
                  >
                    + Bedingung
                  </Button>
                </ButtonGroup>
                {/* <Typography>
                  Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                  feugiat. Aliquam eget maximus est, id dignissim quam.
                </Typography> */}

                {regelnList.map((element, index) => {
                  return (
                    <>
                      <Box className={classes.bedingungsInhalt}>
                        <FormControl
                          sx={{ m: 1, minWidth: 120, marginLeft: "20px" }}
                          size="small"
                        >
                          {/* <InputLabel id="select-object-label">
                            Objekt
                          </InputLabel>
                          <Select
                            labelId="select-object-label"
                            id="select-object-label"
                            value={regelnList?.length > 0 ? element.objekt : ""}
                            label="Objekt"
                            onChange={(event) =>
                              handleChangeFromARule("objekt", event, index)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Produkt-ID"}>Produkt-ID</MenuItem>
                            <MenuItem value={"Umsatz"}>Umsatz</MenuItem>
                            <MenuItem value={"PLZ-Bereich"}>
                              Postleitzahlbereich
                            </MenuItem>
                          </Select> */}
                          <CssTextFieldSmall
                            id="select-object-label"
                            select
                            label="Objekt"
                            value={regelnList?.length > 0 ? element.objekt : ""}
                            onChange={(event) =>
                              handleChangeFromARule("objekt", event, index)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Produkt-ID"}>Produkt-ID</MenuItem>
                            <MenuItem value={"Umsatz"}>Umsatz</MenuItem>
                            <MenuItem value={"PLZ-Bereich"}>
                              Postleitzahlbereich
                            </MenuItem>
                          </CssTextFieldSmall>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          {/* <InputLabel id="select-compair-label">
                            vergleich
                          </InputLabel>

                          <Select
                            labelId="select-compair-label"
                            label="vergleich"
                            value={
                              regelnList?.length > 0 ? element.vergleich : ""
                            }
                            onChange={(event) =>
                              handleChangeFromARule("vergleich", event, index)
                            }
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={">"}>größer als</MenuItem>
                            <MenuItem value={">="}>größer gleich als</MenuItem>
                            <MenuItem value={"="}>gleich</MenuItem>
                            <MenuItem value={"<"}>kleiner als</MenuItem>
                            <MenuItem value={"<="}>kleiner gleich als</MenuItem>
                          </Select> */}
                          <CssTextFieldSmall
                            id="select-compair-label"
                            select
                            label="vergleich"
                            value={
                              regelnList?.length > 0 ? element.vergleich : ""
                            }
                            onChange={(event) =>
                              handleChangeFromARule("vergleich", event, index)
                            }
                          >
                            {/* <MenuItem value={">"}>größer als</MenuItem> */}
                            <MenuItem value={">="}>größer gleich als</MenuItem>
                            <MenuItem value={"="}>gleich</MenuItem>
                            {/*  <MenuItem value={"<"}>kleiner als</MenuItem> */}
                            <MenuItem value={"<="}>kleiner gleich als</MenuItem>
                            <MenuItem value={"in"}>im Bereich</MenuItem>
                          </CssTextFieldSmall>
                        </FormControl>
                        <FormControl
                          style={
                            element.vergleich === "in"
                              ? { display: "none" }
                              : {}
                          }
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                        >
                          <CssTextFieldSmall
                            value={element.werte}
                            onChange={(event) =>
                              handleChangeFromARule("werte", event, index)
                            }
                            /* label="Custom Werte"  */
                            id="custom-css-outlined-input"
                          />
                        </FormControl>
                        <FormControl
                          style={
                            element.vergleich === "in"
                              ? {}
                              : { display: "none" }
                          }
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                        >
                          <CssTextFieldSmall
                            value={element.von}
                            onChange={(event) =>
                              handleChangeFromARule("von", event, index)
                            }
                            label="von"
                            id="custom-css-outlined-input"
                          />
                        </FormControl>
                        <FormControl
                          style={
                            element.vergleich === "in"
                              ? {}
                              : { display: "none" }
                          }
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                        >
                          <CssTextFieldSmall
                            value={element.bis}
                            onChange={(event) =>
                              handleChangeFromARule("bis", event, index)
                            }
                            label="bis"
                            id="custom-css-outlined-input"
                          />
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                          {/* <InputLabel id="select-compair-label">
                            Warengruppe
                          </InputLabel>
                          <Select
                            labelId="select-object-label"
                            id="select-object-label"
                            value={gegenwertType}
                            label="Warengruppe"
                            onChange={(event) =>
                              setGegenwertType(event.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Tiernahrung"}>
                              Tiernahrung
                            </MenuItem>
                            <MenuItem value={"Textilien"}>Textilien</MenuItem>
                            <MenuItem value={"Elektronik"}>Elektronik</MenuItem>
                          </Select> */}{" "}
                          <CssTextFieldSmall
                            id="outlined-select-currency"
                            select
                            label="Warengruppe"
                            value={
                              regelnList?.length > 0
                                ? element.gegenwertType
                                : ""
                            }
                            onChange={(event) =>
                              setGegenwertType(event.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Tiernahrung"}>
                              Tiernahrung
                            </MenuItem>
                            <MenuItem value={"Textilien"}>Textilien</MenuItem>
                            <MenuItem value={"Elektronik"}>Elektronik</MenuItem>
                          </CssTextFieldSmall>
                        </FormControl>
                        <Button
                          size="medium"
                          variant={"contained"}
                          color="error"
                          onClick={() => onClickDeleteRule(index)}
                          className={classes.deleteButton}
                        >
                          Löschen
                        </Button>
                      </Box>
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: "120%" }}
                >
                  Gegenwert
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {/*  <FormControl
                    sx={{ minWidth: 200, marginLeft: "20px" }}
                    size="small"
                  >
                    {" "}
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Warengruppe"
                      value={gegenwertType}
                      onChange={(event) => setGegenwertType(event.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Tiernahrung"}>Tiernahrung</MenuItem>
                      <MenuItem value={"Textilien"}>Textilien</MenuItem>
                      <MenuItem value={"Elektronik"}>Elektronik</MenuItem>
                    </TextField>
                  </FormControl> */}
                  <FormControl
                    sx={{ minWidth: 120, marginLeft: "20px" }}
                    size="small"
                  >
                    <CssTextField
                      required
                      id="outlined-required"
                      label="Rabatt (in Prozent)"
                      value={gegenwertValue}
                      onChange={(event) =>
                        setGegenwertValue(event.target.value)
                      }
                    />
                  </FormControl>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChangeAccordion("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: "120%" }}
                >
                  Gültigkeit
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Gültig von"
                      inputFormat="MM.dd.yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <CssTextField {...params} />}
                    />

                    <DesktopDatePicker
                      label="Gültig bis"
                      inputFormat="MM.dd.yyyy"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <CssTextField {...params} />}
                    />
                  </LocalizationProvider>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>
        <Dialog
          open={confirmDialog}
          onClose={handleCloseConfirmDialog}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Bestätigung
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              display={
                allfilteredConnection.length > 0 && errorValidRules === ""
                  ? "unset"
                  : "none"
              }
            >
              Die folgenden Verbindungen werden den Coupon erhalten:
            </DialogContentText>
            <DialogContentText display={errorValidRules !== "" ? "" : "none"}>
              {errorValidRules}
            </DialogContentText>
            <DialogContentText>
              {allfilteredConnection.length > 0 && errorValidRules === "" ? (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell align="right">Vorname</TableCell>
                        <TableCell align="right">Nachname</TableCell>
                        <TableCell align="right">Stadt</TableCell>
                        <TableCell align="right">PLZ</TableCell>
                        <TableCell align="right">Land</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allfilteredConnection.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="right">{row.firstName}</TableCell>
                          <TableCell align="right">{row.familyName}</TableCell>
                          <TableCell align="right">{row.addressCity}</TableCell>
                          <TableCell align="right">
                            {row.addressZipCode}
                          </TableCell>
                          <TableCell align="right">
                            {row.addressCountry}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : errorValidRules === "" ? (
                "Keine Verbindungen mit passenden Regeln gefunden"
              ) : (
                ""
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ColorButtonCancel autoFocus onClick={handleCloseConfirmDialog}>
              Regeln bearbeiten
            </ColorButtonCancel>
            <ColorButtonConfirm
              onClick={handleConfirmDialog}
              style={errorValidRules === "" ? {} : { display: "none" }}
            >
              Bestätigen
            </ColorButtonConfirm>
          </DialogActions>
        </Dialog>
        <DialogActions className={classes.couponRuleDiaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClose}>
            Abbrechen
          </ColorButtonCancel>
          <ColorButtonConfirm
            onClick={handleCheckRulesAndConfirm /* handleSendCouponWithRules */}
          >
            Ausstellen
          </ColorButtonConfirm>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const CssTextFieldSmall = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "15.5px 14px",
  },
  "& label.Mui-focused": {
    color: "black !important",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
const CssTextField = styled(TextField)({
  "& label.MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "black",
    },
    color: "grey",
  },
  "& label.Mui-focused": {
    color: "black !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
      color: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
      color: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      color: "black",
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "black",
    padding: "1rem 1rem",
  },
});
export default CouponRegelnDialog;
