import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Grid,
  styled,
  Typography,
  Button,
  Stack,
  Box,
  Modal,
  FormControl,
  TextField,
  InputLabel,
  FilledInput,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import background from "../assets/white_background_simple.jpg";
import { useState } from "react";
import CouponRegelnDialog from "./CouponRegelnDialog";
import CouponDialogSelected from "./CouponDialogSelected";
import CouponDialogNotification from "./CouponDialogNotification";
import InteractionDialog from "./InteractionDialog";
import { useEffect } from "react";
import RuleIcon from "@mui/icons-material/Rule";
import LogoRow from "./LogoRow";
import ConfirmDelete from "./ConfirmDelete";

const BMWK = require("../assets/BMWK.jpg");
const IDIDEAL = require("../assets/ID-Ideal.png");

const EECCLogo = require("../assets/eecc.png");
const useStyles = makeStyles((theme) => ({
  MainContainer: {},
  options: {
    height: "50px",
    width: "90%",
    marginTop: "30px",
  },
  dataGrid: {
    height: "55vh",
    width: "100%",
    margin: "9.5vh 2vw 0 3vw",
    //backgroundColor: "rgb(248,248,248)"
    backgroundImage: "url(" + background + ")",
  },

  actionButtons: {
    float: "right",
  },
  actionButtonsLeft: {
    float: "left",
    margin: "0.5rem",
  },
  actionButtonsContainer1: {
    height: "2vh",
    margin: "35px 2vw 0 3vw",
    width: "100%",
  },
  actionButtonsContainer2: {
    height: "2vh",
    margin: "40px 2vw 1rem 3vw",
    width: "100%",
  },
  icon: {
    height: "10vh",
    width: "10vh",
    marginTop: "0.3vh",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  padding: "0.7rem",

  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "white",
    color: "#0F2A47",
  },
}));
const CssTextField = styled(TextField)({
  "& label.MuiInputLabel-root": {
    color: "#ffff",
    fontSize: "80%",
  },
  "& label.Mui-focused": {
    color: "#ffff",
    fontSize: "80%",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffff",
    fontSize: "80%",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ffff",
      fontSize: "80%",
    },
    "&:hover fieldset": {
      borderColor: "green",
      fontSize: "80%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffff",
      fontSize: "80%",
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#ffff",
    fontSize: "80%",
  },
});
const MainPageVendor = (props) => {
  const classes = useStyles();
  const {
    rows,
    columns,
    setOpenSuccessCouponRule,
    setOpenErrorCouponRule,
    setOpenSuccessCoupon,
    setOpenErrorCoupon,
    openCouponDialog,
    vendorToken,
    setOpenCouponDialog,
    onClickSendProofRequest,
    newID,
    handleDelete,
    loadingDataGrid,
  } = props;
  const [rowFilteredToDisplay, setRowFilteredToDisplay] = useState([]);
  /** Filter */
  const [filterVorname, setFilterVorname] = useState("");
  const [filterNachname, setFilterNachname] = useState("");
  const [filterStadt, setFilterStadt] = useState("");
  const [filterPLZ, setFilterPLZ] = useState("");
  const handleResetFilter = () => {
    setFilterVorname("");
    setFilterNachname("");
    setFilterStadt("");
    setFilterPLZ("");
  };
  useEffect(() => {
    let rowFilteredResults = [];
    if (rows !== null) {
      rows.map((e) => {
        if (
          ((e.firstName === null && filterVorname === "") ||
            e.firstName?.toLowerCase().includes(filterVorname.toLowerCase())) &&
          ((e.familyName === null && filterNachname === "") ||
            e.familyName
              ?.toLowerCase()
              .includes(filterNachname.toLowerCase())) &&
          ((e.addressZipCode === null && filterPLZ === "") ||
            e.addressZipCode
              ?.toLowerCase()
              .includes(filterPLZ.toLowerCase())) &&
          ((e.addressCity === null && filterStadt === "") ||
            e.addressCity?.toLowerCase().includes(filterStadt.toLowerCase()))
        ) {
          rowFilteredResults.push(e);
        }
      });

      setRowFilteredToDisplay(rowFilteredResults);
    }
  }, [rows, filterPLZ, filterStadt, filterNachname, filterVorname]);

  const [selectedIDs, setSelectedIDs] = useState([]);
  const [openCouponRuleDialog, setOpenCouponRuleDialog] = useState(false);
  const [openCouponDialogSelected, setOpenCouponDialogSelected] =
    useState(false);
  const [interaction, setOpenInteraction] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleClickOpenCouponRuleDialog = () => {
    setOpenCouponRuleDialog(true);
  };
  const handleCloseCouponRuleDialog = () => {
    setOpenCouponRuleDialog(false);
  };
  const handleCloseCouponDialog = () => {
    setOpenCouponDialog(false);
  };
  const handleCloseCouponDialogSelected = () => {
    setOpenCouponDialogSelected(false);
  };
  const handleCloseInteractionDialog = () => {
    setOpenInteraction(false);
  };
  const handleClickOpenInteractionDialog = () => {
    setOpenInteraction(true);
  };
  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  /* Proof Request Modal functions and style */
  const [openModal, setModalOpen] = React.useState(false);
  const handleModalClose = () => setModalOpen(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const onSelect = (e) => {
    setSelectedIDs(e);
  };

  const handleClickSendCoupon = () => {
    setOpenCouponDialogSelected(true);
  };

  return (
    <Grid container spacing={3} className={classes.MainContainer}>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Daten Anfrage gesendet!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>
              Kunde wurde benachrichtigt, dass Sie folgenden Daten von ihm
              einsehen möchten:{" "}
            </p>
            <ul>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Stadt</li>
              <li>Land</li>
              <li>Wohnort</li>
              <li>Postleitzahl</li>
            </ul>
          </Typography>
        </Box>
      </Modal>
      <Grid className={classes.actionButtonsContainer1}>
        <Stack direction="row" spacing={2} className={classes.actionButtons}>
          <ColorButton
            size="small"
            variant="contained"
            style={{
              backgroundColor: "#0F2A47 !important",
              fontSize: "80%",
            }}
            onClick={handleClickOpenCouponRuleDialog}
            startIcon={<RuleIcon />}
          >
            Neuen Coupon erstellen
          </ColorButton>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          className={classes.actionButtonsLeft}
        >
          <ColorButton
            variant="contained"
            size="small"
            href="#contained-buttons"
            onClick={handleResetFilter}
            style={{ fontSize: "80%" }}
          >
            Filter zurücksetzen
          </ColorButton>
        </Stack>
      </Grid>
      <Grid className={classes.actionButtonsContainer2}>
        <Stack
          direction="row"
          spacing={2}
          className={classes.actionButtonsLeft}
        >
          <FormControl size="small" variant="standard">
            <CssTextField
              label="Vorname"
              id="custom-css-outlined-input"
              value={filterVorname}
              onChange={(event) => setFilterVorname(event.target.value)}
            />
          </FormControl>
          <FormControl size="small" variant="filled">
            <CssTextField
              label="Nachname"
              id="custom-css-outlined-input"
              value={filterNachname}
              onChange={(event) => setFilterNachname(event.target.value)}
            />
          </FormControl>
          <FormControl size="small" variant="filled">
            <CssTextField
              label="Postleitzahl"
              id="custom-css-outlined-input"
              value={filterPLZ}
              onChange={(event) => setFilterPLZ(event.target.value)}
            />
          </FormControl>
          <FormControl size="small" variant="filled">
            <CssTextField
              label="Stadt"
              id="custom-css-outlined-input"
              value={filterStadt}
              onChange={(event) => setFilterStadt(event.target.value)}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} className={classes.actionButtons}>
          {/*<ColorButton
            variant="contained"
            size="small"
            style={{ backgroundColor: "#0F2A47 !important", fontSize: "80%" }}
            disabled={selectedIDs.length > 0 ? false : true}
            onClick={handleClickSendCoupon}
          >
            Coupon senden
          </ColorButton>*/}
          <ColorButton
            variant="contained"
            size="small"
            disabled={selectedIDs.length > 0 ? false : true}
            onClick={() => onClickSendProofRequest(selectedIDs)}
            style={{ fontSize: "80%" }}
          >
            Kundendaten Anfragen
          </ColorButton>
          <ColorButton
            variant="contained"
            size="small"
            href="#contained-buttons"
            disabled={selectedIDs.length > 0 ? false : true}
            onClick={handleClickOpenInteractionDialog}
            style={{ fontSize: "80%" }}
          >
            Verlauf der Interaktion
          </ColorButton>
          <ColorButton
            variant="contained"
            size="small"
            href="#contained-buttons"
            disabled={selectedIDs.length > 0 ? false : true}
            onClick={handleOpenConfirmDelete}
            style={{ fontSize: "80%" }}
          >
            Verbindung Löschen
          </ColorButton>
        </Stack>
      </Grid>
      <Grid className={classes.dataGrid}>
        {/** Regeln */}
        <CouponRegelnDialog
          vendorToken={vendorToken}
          open={openCouponRuleDialog}
          handleClose={handleCloseCouponRuleDialog}
          handleClickOpen={handleClickOpenCouponRuleDialog}
          setOpenSuccessSendCouponRule={setOpenSuccessCouponRule}
          setOpenErrorSendCouponRule={setOpenErrorCouponRule}
          newID={newID}
          allConnections={rows}
        />
        {/** Benachrichtigung */}
        <CouponDialogNotification
          vendorToken={vendorToken}
          open={openCouponDialog}
          handleClose={handleCloseCouponDialog}
          setOpenSuccessSendCoupon={setOpenSuccessCoupon}
          setOpenErrorSendCoupon={setOpenErrorCoupon}
          newID={newID}
        />
        {/** Ausgewählte */}
        <CouponDialogSelected
          vendorToken={vendorToken}
          open={openCouponDialogSelected}
          handleClose={handleCloseCouponDialogSelected}
          setOpenSuccessSendCoupon={setOpenSuccessCoupon}
          setOpenErrorSendCoupon={setOpenErrorCoupon}
          selectedIDs={selectedIDs}
        />
        <InteractionDialog
          open={interaction}
          handleClose={handleCloseInteractionDialog}
        />
        <ConfirmDelete
          open={openConfirmDelete}
          handleClose={handleCloseConfirmDelete}
          handleDelete={handleDelete}
          selected={selectedIDs}
        />
        <DataGrid
          loading={loadingDataGrid}
          autoPageSize
          style={{ fontSize: "80%" }}
          rows={rowFilteredToDisplay}
          columns={columns}
          // pageSize={pageSize}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(e) => onSelect(e)}
        />
      </Grid>
      <LogoRow
        classes={classes}
        logo1={EECCLogo}
        logo2={BMWK}
        logo3={IDIDEAL}
      />
    </Grid>
  );
};
export default MainPageVendor;
