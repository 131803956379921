import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Grid,
  styled,
  Paper,
  Typography,
  Button,
  Stack,
  Stepper,
  StepLabel,
  Box,
  Step,
} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const LogoRow = (props) => {
  const { classes, logo1, logo2, logo3 } = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "2vw",
        marginTop: "0.5vh",
        marginBottom: "0.5vh",
      }}
    >
      <Grid container spacing={25} alignItems="center" justifyContent="center">
        <Grid item xs={4}>
          <Item>
            <img src={logo1} alt="Logo" className={classes.icon} />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <img src={logo2} alt="Logo" className={classes.icon} />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <img src={logo3} alt="Logo" className={classes.icon} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LogoRow;
